body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.not-found{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #C6E9FF;
}

.not-found h4{
  font-family: "AvantGarde-Demi", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.1em;
  color: #032D60;
  padding: 0px 10%;
  text-align: center;
}

@font-face {
  font-family: "AvantGarde-Demi";
  src: url("./assets/fonts/AvantGarde-Demi.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SalesforceSans-Regular";
  src: url("./assets/fonts/SalesforceSans-Regular.woff2") format("woff2"),
    url("./assets/fonts/SalesforceSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SalesforceSans-Bold";
  src: url("./assets/fonts/SalesforceSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/SalesforceSans-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SalesforceSans-Thin";
  src: url("./assets/fonts/SalesforceSans-Thin.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
