.font-app-title{
  font-family: "AvantGarde-Demi", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #032D60;
}

.font-app-subtitle{
  font-family: "SalesforceSans-Regular", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #181818;
}

.font-app-body{
  font-family: "SalesforceSans-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #181818;
}

.font-app-body-error{
  font-family: "SalesforceSans-Regular", sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #BA0517;
}