.panoramic__view{
  position: absolute;
  height: 100%;
  top: 0px;
  z-index: 1;
  display: none;
}

.panoramic__view.active{
  display: block;
}

.panoramic__view .landmark{
  position: absolute;
  z-index: 1;
  transform: translate(-50%, 0px);
}

.panoramic__view .landmark.active{
  position: fixed;
  padding: 0px 10vw;
  z-index: 2;
}

.panoramic__view .landmark.active button{
  opacity: 1;
  transition: opacity 0.5s;
  transition-delay: 1.5s;
}

.panoramic__view.pinned .landmark.inactive{
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.50;
  pointer-events: none;
}

.panoramic__view .text{
  height: auto;
  font-family: monospace;
  font-size: 18px;
  z-index: 1;
  text-align: center;
}



.landmark .information-content{
  /* margin: 0px 5%; */
  position: relative;
}

.landmark .image{
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.9s;
  position: relative;
}
.landmark .image .image-container{
  position: relative;
  height: 100%;
}
.landmark img{
  height: 100%;
}

.landmark .image .arrow{
  position: absolute;
  transition: opacity 0.5s, left 1s, right 1s;
  top: 66.875%;
  transform: translateY(-50%);
}
.landmark .image .arrow.inactive{
  opacity: 0;
  transition: none;
}
.landmark .image .arrow.right{
  left: 100%;
  animation: arrowRightInch;
  animation-duration: 4s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}
.landmark .image .arrow.left{
  right: 100%;
  transform: translateY(-50%) rotate(180deg);
  animation: arrowLeftInch;
  animation-duration: 4s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}

.landmark .image button{
  position: absolute;
  top: calc(100% - 43px);
  left: calc(100% + -18px);
  border-radius: 50%;
  border: 0px;
  width: 36px;
  height: 36px;
  filter: drop-shadow(0px 2px 2px rgba(50, 39, 39, 0.15));
  color: #979797;
  font-weight: 800;
  background-color: white;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5px 0px 0px 1.5px;
}
.landmark .image button svg{
  width: 14px;
  height: 14px;
}

.landmark .information{
  /* background: #FFFFFF; */
  background: #FFFFFFDF;
  padding: 0.5em 1.2em 24px;
  border-radius: 15px;
  position: relative;
  top: -25px;
  width: 0px;
  z-index: -1;
}
.landmark.active .information{
  width: auto;
  opacity: 1;
  transition: opacity 0.9s;
  transition-delay: 0.5s;
}
.landmark.inactive .information{
  opacity: 0;
  transition: opacity 0.9s;
}

.landmark .information .overflowed{
  overflow-y: scroll;
  max-height: 200px;
  padding: 0 0.7em;
  margin-top: 1.5em;
  width: auto;
}

.landmark .information .overflowed::-webkit-scrollbar{
  width: auto;
  height: auto;
}

.landmark .information .overflowed::-webkit-scrollbar-thumb{
  background: grey;
}

.landmark .information .overflowed h4{
  font-family: "AvantGarde-Demi", sans-serif;
  color: #032D60;
  font-size: 20px;
  margin: 0px 0px 1.2em;
}

.landmark .information .overflowed p{
  font-family: "SalesforceSans-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #181818;
}

@keyframes arrowLeftInch {
  12.5%  {
    right: calc(100% + 10px);
  }
  25%  {
    right: 100%;
  }
}

@keyframes arrowRightInch {
  12.5%  {
    left: calc(100% + 10px);
  }
  25%  {
    left: 100%;
  }
}


@media screen and (orientation:landscape) and (pointer: coarse) { 
  .panoramic__view .landmark{
    height: 30%;
  }
  .information-content{
    margin: 0px 20%;
  }
  .landmark .image{
    height: 120px;
  }
  .landmark .information .overflowed {
    max-height: 120px;
  }
}