header{
  position: sticky;
  top: 0px;
  background-color: rgba(255, 255, 255,0.8);
  height: 50px;
  width: 100%;
  /* max-width: 800px; */
  z-index: 50;
  box-sizing: border-box;
  border-bottom: 1px solid #DBD7D4;
}

header .container{
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

header .container img{
  height: 70%;
  margin: 0px auto 0px 1em;
}

header .container a{
  text-decoration: none;
}

header .container a p{
  font-family: "SalesforceSans-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #215CA0;
  padding: 0.4em 0.6em;
  margin: 0px;
}
header .container a + a p{
  border-left: 1px solid #C1B9B4;
}