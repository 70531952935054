.main{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc( 100vh - 50px);
}

.panoramic{
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.panoramic .orientation-test{
  position: absolute;
  color: black;
  background: white;
  left: 50%;
  padding: 4px;
  top: 0px;
}

.panoramic .calibration-icon-test{
  position: absolute;
  right: 8%;
  top: 120px;
  z-index: 2;
  width: 24px;
  opacity: 0;
}

.panoramic .calibration-icon-test.active{
  opacity: 1;
}

.panoramic .calibration-icon-test img{
  width: 100%;
  border-radius: 50%;
}

.panoramic .calibration-icon-test .tooltip{
  position: absolute;
  right: calc(100% + 16px);
  font-size: 10px;
  background-color: white;
  padding: 0.5em;
  width: 80px;
  opacity: 0;
  transition: all 1s;
}

.panoramic .calibration-icon-test .tooltip.active{
  opacity: 1;
}


.panoramic .calibration-icon-test .tooltip::after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 100%;
  border-style: solid;
  border-color: transparent #FFF;
  border-width: 6px 0px 6px 12px;
  width: 0px;
  height: 0px;
}

.video-container{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.video-container.active{
  opacity: 1;
}

.video-container video{
  height: 100%;
}

.video-container.active .calibration-image{
  width: 50%;
  position: absolute;
  opacity: 0.8;
  max-width: 400px;
}

.video-container.active .shadow-section{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  /* -webkit-clip-path: polygon(0% 0%, 0% 100%, 15% 100%, 15% 15%, 85% 15%, 85% 85%, 15% 85%, 15% 100%, 100% 100%, 100% 0%); */
  clip-path: polygon(0% 0%, 0% 100%, 15% 100%, 15% 15%, 85% 15%, 85% 85%, 15% 85%, 15% 100%, 100% 100%, 100% 0%);
  /* clip-path: url("./clipath1.svg"); */
  background-color: rgba(0,0,0,0.3);
}

.video-container .shadow-section .text{
  position: absolute;
  bottom: 85%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.video-container .shadow-section .text p{
  margin: 0px 15% 0.5em;
  color: white;
  text-align: center;
  font-family: "SalesforceSans-Regular", sans-serif;
}

.video-container .calibration-button{
  position: absolute;
  bottom: 5%;
  z-index: 1;

  cursor: pointer;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  letter-spacing: .3px;
  padding: 13px 24px;
  border-radius: 4px;
  background-color: #0176d3;
  color: #fff;
  border: 2px solid #0176d3;
  display: block;
  font-family: "SalesforceSans-Regular", sans-serif;
  font-size: 14px;
  
}



.toggle{
  position: absolute;
  top: 2%;
  left: 2%;
  z-index: 2;
}

.toggle p{
  background-color: white;
  font-family: 'SalesforceSans-Regular';
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}







@media only screen and (orientation: landscape ) and (pointer: coarse){
  body{
    overflow: hidden;
  }
  .armap {
    height: 100vw;
    width: 100vh;
    transform: rotate(90deg) translateY(-100%);
    transform-origin: top left;
    /* background: #eee;
    background-image: linear-gradient(45deg, rgba(0,0,0,.25) 25%,
                      transparent 0, transparent 75%, rgba(0,0,0,.25) 0),
                      linear-gradient(45deg, rgba(0,0,0,.25) 25%,
                      transparent 0, transparent 75%, rgba(0,0,0,.25) 0);
    background-position: 0 0, 25px 25px;
    background-size: 50px 50px; */
  }
  .armap::after{
    content: "ROTATE";
    position: fixed;
    top: 50%;
    left: 25px;
    transform: rotate(-90deg);
    font-size: 24px;
    color: white;
    background-color: #0176d3;
    border-radius: 20px;
    padding: 10px 20px;
  }
  .main{
    height: 100% !important;
  }
  .video-container{
    height: auto;
  }
  .video-container video{
    /* width: 100%; */
    height: 100vw;
  }
}