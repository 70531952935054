.compass-container{
  position: absolute;
  display: none;
  width: 48px;
  height: 48px;
  border: 2px solid white;
  border-radius: 50%;
  box-sizing: border-box;
  top: calc(3% + 18px);
  right: calc(3% + 18px);
  box-shadow: 0px 2px 10px rgb(0 0 0 / 40%);
  /* bottom: 19px;
  right: 34px; */
}

.compass-container.active{
  display: flex;
  justify-content: center;
  align-items: center;
}

.compass-container .coordinate{
  position: absolute;
  color: white;
  font-family: "AvantGarde-Demi", sans-serif;
  font-size: 12px;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: center;
}
.compass-container .coordinate.north{
  bottom: 100%;
  margin-bottom: 0.5em;
}
.compass-container .coordinate.east{
  left: 100%;
  margin-left: 0.5em;
}
.compass-container .coordinate.south{
  top: 100%;
  margin-top: 0.5em;
}
.compass-container .coordinate.west{
  right: 100%;
  margin-right: 0.5em;
}

.compass-container .triangle{
  position: absolute;
  width: 0px;
  height: 0px;
}
.compass-container .triangle.north{
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid white;
  top: -1px;
}
.compass-container .triangle.east{
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 6px solid white;
  right: -1px;
}
.compass-container .triangle.south{
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: 6px solid white;
  bottom: -1px;
}
.compass-container .triangle.west{
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid white;
  left: -1px;
}

.compass-container .compass-arrow{
  width: 16px;
  height: 16px;
}