.brochure{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.introduction{
  position: relative;
  min-height: 600px;
}

.introduction .container{
  position: absolute;
  top: 0px;
  text-align: center;
  /* margin: 0px 14%; */
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.introduction .container .title{
  font-family: "AvantGarde-Demi", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 8.2vw;
  line-height: 1.1em;
  color: #032D60;
  padding: 0 5%;
}

.introduction .container .floating{
  opacity: 0;
  visibility: hidden;
  transition: opacity 1500ms ease-out, transform 1000ms ease-out;
  /* will-change: opacity, visibility; */
  transition-delay: 0.5s;
}

.introduction .container .floating.active{
  opacity: 1;
  /* transform: none; */
  visibility: visible;
}

.introduction .container .cloud-1{
  position: absolute;
  width: 56%;
  max-width: max-content;
  top: 13%;
  left: 0%;
}
.introduction .container .cloud-2 {
  position: absolute;
  width: 45%;
  max-width: max-content;
  top: 17%;
  right: 0%;
}
.introduction .container .cloud-3 {
  position: absolute;
  width: 87%;
  max-width: max-content;
  top: 23.5%;
  left: 0%;
}
.introduction .container .cloud-4{
  position: absolute;
  width: 44%;
  max-width: max-content;
  top: 33%;
  right: 0%;
}
.introduction .container .cloud-5{
  position: absolute;
  width: 51%;
  max-width: max-content;
  top: 37%;
  right: 37%;
}
.introduction .container .cloud-6{
  position: absolute;
  width: 50%;
  max-width: max-content;
  top: 44%;
  right: 0%;
}
.introduction .container .cloud-7 {
  position: absolute;
  width: 42%;
  max-width: max-content;
  top: 44%;
  left: 0%;
}
.introduction .container .cloud-8 {
  position: absolute;
  width: 48%;
  max-width: max-content;
  top: 52%;
  left: 16%;
}
.introduction .container .cloud-9 {
  position: absolute;
  width: 38%;
  max-width: max-content;
  top: 57%;
  right: 0%;
}
.introduction .container .cloud-10 {
  position: absolute;
  width: 53%;
  max-width: max-content;
  top: 59%;
  left: 0%;
}
.introduction .container .cloud-11 {
  position: absolute;
  width: 45%;
  max-width: max-content;
  top: 66%;
  right: 11%;
}


.introduction .container .cloudy-3{
  position: absolute;
  width: 35%;
  max-width: max-content;
  top: 24%;
  right: -2%;
  transform: scaleX(-1);
}
.introduction .container .bird{
  position: absolute;
  width: 20%;
  max-width: max-content;
  top: 22%;
  left: 15%;
  z-index: 1;
}

.introduction .container p{
  font-family: "SalesforceSans-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3.1vw;
  line-height: 1.4em;
  margin: 8% 0px;
}

.introduction .brochure-tower{
  min-width : 100vw;
  width: 100%;
  min-height: 900px;
  /* background: linear-gradient(180deg, #01B2CE 13.02%, #2ABCD2 25.06%, #69CDD9 44.52%, #98D9DD 61.19%, #B4E1E0 73.23%, #BFE4E2 80.64%, #fff 82%); */
}

.brochure .cards{
  width: 100%;
  margin-bottom: 2em;
}

.cards .title{
  font-family: "AvantGarde-Demi", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 7.2vw;
  line-height: 1.1em;
  color: #032D60;
  text-align: center;
  margin: 50px 1.5em;
}

.cards .cards__container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px 5vw;
  gap: 60px;
}

.cards__container .card{
  position: relative;
  border-radius: 24px;
  background-color: white;
  max-width: 560px;
  filter: drop-shadow(0px 12px 48px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.04));
  flex: 1;
  display: flex;
  align-items: flex-end;
  aspect-ratio: 16/9;
}

.cards__container .card .box{
  width: 100%;
  height: 65%;
  background: rgba(198, 233, 255, 0.46);
  border-radius: 16px;
  margin: 0px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cards__container .card .box.inactive{
  pointer-events: none;
}

.cards__container .card .box.card-salesforce{
  background: #C6E9FF75;
}
.cards__container .card .box.card-ohana-floors{
  background: #DEF3F2;
}
.cards__container .card .box.card-sustainability{
  background: #FCF0CC;
}

.cards__container .card .box.card-salesforce img{
  width: 65%;
  max-width: 300px;
  position: absolute;
  top: -20px;
}

.cards__container .card .box.card-ohana-floors img{
  width: 70%;
  max-width: 300px;
  position: absolute;
  top: -16px;
}

.cards__container .card .box.card-sustainability img{
  width: 85%;
  max-width: 300px;
  position: absolute;
  top: -16px;
}

.cards__container .card .box h4{
  font-family: "AvantGarde-Demi", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 6.2vw;
  line-height: 38px;
  color: #032D60;
  margin: 0px;
  padding: 50px 1em 0px;
  text-align: center;
}

.modal-card{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 100%;
  background-color: #FFF;
  z-index: 51;
  transition: top 1s;
  display: flex;
  flex-direction: column;
}

.modal-card.active{
  top: 0px;
}

.modal-card .header{
  background: #032D60;
}

.header .arrow__container{
  height: 50px;
  padding-left: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
} 

.header .arrow__container svg{
  transform: rotate(180deg);
}    

.header .arrow__container svg path{
  fill: white;
}

.modal-card h4{
  font-family: "AvantGarde-Demi", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 140%;
  color: #032D60;
  padding: 0.8em 24px 0.4em;
  margin: 0px;
}

.article{
  /* height: calc(100% - 50px); */
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  overflow: scroll;
}

.article .body{
  flex: 1;
  overflow-y: scroll;
  padding: 0 0 4em;
  -webkit-mask-image: linear-gradient(to bottom, black calc(100% - 5em), transparent 100%);
  mask-image: linear-gradient(to bottom, black calc(100% - 5em), transparent 100%);
}
.article .body::-webkit-scrollbar {
  display: none;
}

.article .body p{
  font-family: "SalesforceSans-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #222222;
  margin: 0;
}

.article .body p:not(:last-child){
  margin: 0 0 2em;
}

.article .image__container{
  display: flex;
  justify-content: center;
  margin: 0px -24px 0px;
}

.article .image__container.card-salesforce img{
  background-image: url("../assets/images/brochure/sanfrancisco/card-modal-image-artwork.png");
  background-position: bottom center;
  background-size: 110%;
  max-width: 625px;
  width: 100%;
}
.article .image__container.card-ohana-floors img{
  max-width: 625px;
  width: 100%;
}
.article .image__container.card-sustainability img{
  max-width: 625px;
  width: 100%;
}


@media screen and (min-width: 640px) {
  .introduction .container .title {
    font-size: 53px;
  }
  .cards .title {
    font-size: 46px;
  }
  .cards__container .card{
    min-height: 300px;
  }
  .cards__container .card .box h4{
    font-size: 40px;
  }
  .article h4{
    font-size: 32px;
  }
  .article p {
    font-size: 22px;
    line-height: 36px;
  }
}

@media screen and (max-height: 520px) and (orientation: landscape) {
  .article{
    flex-direction: row;
  }
  .article .image__container{
    margin: auto 0px auto 10px;
  }
  .article .image__container img{
    width: auto !important;
    max-height: 35vh;
  }
}