.instructions {
  position: absolute; /* Stay in place */
  z-index: 3; /* Sit on top */
  /* padding-top: 100px; */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-image: url("../assets/images/trailmap/instructions-background.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: lightblue;
}
.instructions .content {
  /* display: flex; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  color: white;
}

.instructions .content h4 {
  font-family: "AvantGarde-Demi", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #032D60;
  align-self: flex-start;
  margin: 0.5em 0px;
}

.instructions .content p {
  font-family: "SalesforceSans-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #181818;
}

.instructions .content button{
  cursor: pointer;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  letter-spacing: .3px;
  padding: 0.5em 1.5em;
  border-radius: 4px;
  background-color: #0176d3;
  border: 1px solid #0176d3;
  color: #fff;
  display: block;
  font-family: SalesforceSans-Regular,sans-serif;
  font-size: 16px;
  margin-top: 0.5em;
}

.permissions {
  position: absolute;
  z-index: 2;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
}
.permissions::before {    
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url("../assets/images/trailmap/instructions-background-2.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
.permissions.error::before {
  transition: opacity 1s;
  opacity: 0.5;
}
.permissions .content {
  /* display: flex; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  color: white;
}

.permissions .content h4 {
  align-self: flex-start;
  margin: 0.5em 0px;
}

.permissions .content .row h3, .permissions .content .row p{
  margin: 0px;
}

.permissions .content .row button{
  cursor: pointer;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  letter-spacing: .3px;
  padding: 0.5em 1.5em;
  border-radius: 4px;
  background-color: #0176d3;
  border: 1px solid #0176d3;
  color: #fff;
  display: block;
  font-family: SalesforceSans-Regular,sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  margin-left: 12px;
}

.permissions .content .row button.error{
  background-color: #d83a00;
  border: 1px solid #d83a00;
}

.permissions .content .row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0.5rem 0px;
}

.permissions .error-message{
  align-self: flex-start;
  margin: 0px;
}

.permissions .error-title{
  color: #BA0517;
  align-self: flex-start;
  font-weight: bold;
  margin-bottom: 0px;
}

.permissions ol{
  list-style-position: inside;
  align-self: flex-start;
  margin: 0px;
  padding: 0px;
}

/* LOADER */
.loader-spin {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  transform: scale(1);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* CHECK */
.permissions .checkmark {
  display:inline-block;
  width: 22px;
  height:22px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.permissions .checkmark_stem {
  position: absolute;
  width: 3px;
  height: 15px;
  background-color: #fff;
  left: 11px;
  top: 3px;
}

.permissions .checkmark_kick {
  position: absolute;
  width: 4px;
  height: 3px;
  background-color: #fff;
  left: 7px;
  top: 15px;
}

/* CROSS */
.permissions .cross{
  /* color: #ea001e */
  color: #fff
}